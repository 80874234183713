import { handleAxiosError } from '@/helpers';

export default {
    namespaced: true,

    state: () => ({
        user: null,
        token: null,
        contract_name: null,
        sidebarInfo: {
            total_searches: null,
            total_used_searches: null,
            positions: null
        }
    }),

    mutations: {
        setUser(state, user) {
            state.user = user;
            if (user === null) {
                localStorage.removeItem('_user');
            } else {
                localStorage.setItem('_user', JSON.stringify(user));
            }
        },

        setToken(state, token) {
            state.token = token;
            if (token === null) {
                localStorage.removeItem('_token');
            } else {
                localStorage.setItem('_token', token);
            }
        },

        setContractName(state, contractName) {
            state.contract_name = contractName;
            if (contractName === null) {
                localStorage.removeItem('contract_name');
            } else {
                localStorage.setItem('contract_name', contractName);
            }
        },
        
        setSidebarInfo(state, sidebarInfo) {
            state.sidebarInfo = sidebarInfo;
        },
    },

    getters: {
        isAuth(state) {
            return (state.token && state.user) ? true : false;
        },
        isSupported(state) {
            return state.user?.permissions && state.user?.permissions.includes('is-support');
        },
        permissions(state) {
            return state.user?.permissions;
        },
        initialLetters(state) {
            if (state.user) {
                const dataName = state.user.name.split(' ');
                return `${dataName[0].charAt(0)}${dataName[dataName.length - 1].charAt(0)}`.toUpperCase();
            }
            return '';
        }, 
    },

    actions: {
        async accessLog(_, formData) {
            try {
                await axios.post(`/access-log`, formData);
            } catch (e) {
                console.log(e);
            }
        },

        async login({ dispatch, commit }, credentials) {
            try {
                const { data } = await axios.post('login', credentials);
                
                if (data.user.update_password) {
                    window.location.href = '/redefinir-senha/' + data.user.remember_token + '?login=' + credentials.login + '&type=' + data.redirect;
                } else {                    
                    commit('setToken', data.token);
                    commit('setUser', data.user);
                    commit('setContractName', data.contract_name);                    
                    commit('setContractDateStart', data.contract_date_start);

                    if (data.redirect === 'admin') {
                        window.location.href = '/';
                    } else {
                        window.location.href = '/portal';
                    }
                }
                
            } catch (e) {
                throw e;
            }
        },

        async logout({ dispatch, commit }) {
            
            const handler = () => {
                commit('setToken', null);
                commit('setUser', null);                
                commit('setContractName', null);
                this.dispatch('relationalNps/clearRelationalNpsData');
                this.dispatch('transactionalNps/removeLastSearchPerformedDate');
            }

            try {
                await axios.get('logout');
                handler();                
            } catch (e) {
                handler();            
            }finally {                
                window.location.href = '/login';
            }

        },

        async getSidebarInfo({ dispatch, commit }) {
            const token = localStorage.getItem('_token');
            if (token) {
                try {
                    const { data } = await axios.get('sidebar-info');
                    commit('setSidebarInfo', {
                        total_searches: data.totalSearches,
                        total_used_searches: data.totalUsedSearches,
                        positions: data.positions,
                    });
                } catch (e) {
                    handleAxiosError(e);
                }
            }
        },

        loadUserData({ state, commit }) {
            const storageToken = localStorage.getItem('_token');
            const storageUser = JSON.parse(localStorage.getItem('_user'));
            const storageContractName = localStorage.getItem('contract_name');            
            
            if (!state.user) commit('setUser', storageUser);
            if (!state.token) commit('setToken', storageToken);
            if (!state.contract_name) commit('setContractName', storageContractName);            
        }
    }
};
